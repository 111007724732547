



































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";
import {
    a11yFixBuefyModalAriaAttrs,
    a11yFixBuefyNotificationAriaAttrs
} from "@/services/a11y";
import { v4 as uuidv4 } from "uuid";

import MeetingModal from "@/components/networking/MettingModal.vue";
import NetworkingRoomLounge from "@/components/networking/svg/NetworkingRoomLounge.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import NotFound from "@/views/NotFound.vue";

import { MeetingTable } from "@/types/interfaces";

//vuex Module imports
import NetworkingModule from "@/store/vuex-modules/networking";
const networkingStore = getModule(NetworkingModule);

import LogVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(LogVuexModule);

@Component({
    components: {
        NetworkingRoomLounge,
        MeetingModal,
        Spinners,
        NotFound
    },
    directives: {
        addSvg: {
            inserted() {
                eventHub.$emit("NetworkingRoomSvgInserted");
            }
        },
        addElementWithActiveClassToggle: {
            inserted(el) {
                eventHub.$emit(
                    "NetworkingRoomElementWithActiveClassToggleInserted",
                    el
                );
            }
        },
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    }
})
export default class LandingPage extends Vue {
    @Prop({
        type: String,
        default: "",
        required: true
    })
    id!: string;

    componentId = "";
    isLoading = true;
    isModalOpen = false;
    meetingTables: Array<MeetingTable> = [];
    hasRoomsData = false;
    speakeasyData: Partial<MeetingTable> = {};
    tablesInterval = -1;
    audioPaused = true;
    pageVisitLogged = false;

    /**
     * Computed
     */
    get userInfo() {
        return this.$store.getters.userInfo;
    }

    get pageOptions() {
        return this.$store.getters.getPageOptions("networking") || {};
    }

    get legendLogo(): string {
        let returnValue = "";
        const filename = this.pageOptions.legendLogo;

        if (filename) {
            returnValue = `/legend-logos/${filename}`;
        }

        return returnValue;
    }

    get audioFile(): string {
        let returnValue = "";
        const filename = this.pageOptions.audioFile;

        if (filename) {
            returnValue = `/audio/${filename}`;
        }

        return returnValue;
    }

    get helpText(): string {
        const returnValue = this.pageOptions.helpText || "";
        return returnValue;
    }

    /**
     * Lifecycle
     */
    created() {
        this.componentId = uuidv4();

        eventHub.$on("NetworkingRoomSvgInserted", this.handleRoomItemsInit);
        eventHub.$on(
            "NetworkingRoomElementWithActiveClassToggleInserted",
            this.handleAnimationElement
        );
        eventHub.$on("getUserinfo-loaded", this.logPageVisit);
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        this.handleTablesFetch();
        this.logPageVisit();
    }

    beforeDestroy() {
        eventHub.$off("NetworkingRoomSvgInserted", this.handleRoomItemsInit);
        eventHub.$off(
            "NetworkingRoomElementWithActiveClassToggleInserted",
            this.handleAnimationElement
        );
        eventHub.$off("getUserinfo-loaded", this.logPageVisit);
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        window.clearInterval(this.tablesInterval);
    }

    /**
     * Methods
     */
    getLetter(sessionId: string) {
        let returnValue = "";

        if (sessionId) {
            const sessionIdParts = sessionId.split("-");
            const tableNumber = parseInt(
                sessionIdParts[sessionIdParts.length - 1]
            );
            const alphabet = Vue.prototype.MgGetAlphabet();
            returnValue = alphabet[tableNumber - 1];
        }

        return returnValue;
    }

    handleTablesFetch() {
        // first cancel existing interval
        window.clearInterval(this.tablesInterval);

        // run immediatelty
        this.fetchMeetingtables();

        // run again on interval
        this.tablesInterval = window.setInterval(this.fetchMeetingtables, 5000);
    }

    fetchMeetingtables() {
        const id = this.id;

        networkingStore
            .getTables(id)
            .then((x: unknown) => {
                const response = x as Array<MeetingTable>;

                if (response.length) {
                    this.hasRoomsData = true;
                    this.meetingTables = response;

                    this.logPageVisit();
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    updateAttendeeBadgeNumber() {
        const tables = this.meetingTables;

        tables.map((item: MeetingTable) => {
            const id = item.sessionId.split("-").join("");
            const eL = document.getElementById(id);
            if (eL) {
                const tspan = eL.querySelector("tspan");
                if (tspan) {
                    tspan.innerHTML = `${item.numAttendees}`;
                }
            }
        });
    }

    handleRoomItemsInit() {
        const rooms = this.meetingTables;

        rooms.forEach((item: MeetingTable) => {
            const eL = document.querySelector(`#${item.sessionId}`);

            if (eL) {
                eL.classList.add("cursor-pointer");

                eL.addEventListener("touch", () => {
                    this.openRoomModal(item);
                });
                eL.addEventListener("click", () => {
                    this.openRoomModal(item);
                });
            }
        });
    }

    openRoomModal(data: MeetingTable) {
        const match = this.meetingTables.find((item) => {
            return item.sessionId === data.sessionId;
        });

        if (match) {
            this.speakeasyData = match;
            this.isModalOpen = true;
        }
    }

    closeModal() {
        this.isModalOpen = false;
    }

    toggleMusic(event: Event) {
        const el = document.querySelector(`#audio-element-${this.componentId}`);
        const target = event.target as HTMLButtonElement;
        const wrapEl = target.closest(".button-wrap");

        if (wrapEl instanceof HTMLElement) {
            wrapEl.classList.add("active");
        }

        if (el instanceof HTMLAudioElement) {
            if (el.paused) {
                el.play();
            } else {
                el.pause();
            }
            this.audioPaused = el.paused;
        } else {
            console.error("No audio element.");
        }
    }

    handleAnimationElement(animated: HTMLElement) {
        animated.addEventListener("animationend", () => {
            animated.classList.remove("active");
        });
    }

    logPageVisit() {
        const uid = this.userInfo.id;
        const username = this.userInfo.email;
        const name = this.userInfo.name;
        const networkingId = this.id;

        if (this.pageVisitLogged || !uid || !this.hasRoomsData) {
            return;
        }

        const logData = {
            type: `NetworkingLanding`,
            uid,
            username,
            name,
            networkingId
        };

        logStore
            .appendLogEntry(logData)
            .then(() => {
                this.pageVisitLogged = true;
            })
            .catch((err) => console.log(err));
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }

    handleBuefyNotificationFixes(el: HTMLElement) {
        a11yFixBuefyNotificationAriaAttrs(el);
    }
}
