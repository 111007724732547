














































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    MeetingTable,
    SessionDataObject,
    AttendeeBase
} from "@/types/interfaces";
import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver
} from "@/services/session-helpers";

import Spinners from "@/components/utilities/Spinners.vue";

import getSessionData from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(getSessionData);

@Component({ components: { Spinners } })
export default class MeetingModal extends Vue {
    @Prop({ type: Object })
    data!: MeetingTable;

    @Prop(String)
    roomLetter!: string;

    sessionData: Partial<SessionDataObject> = {};
    isLoading = true;
    sessionLabelInterval = -1;
    isSessionActive = false;
    isSessionOver = false;

    /**
     * Computed
     */
    get currentAttendees() {
        return this.sessionData.currentAttendees || [];
    }

    get sessionDateTime() {
        let dateToUse = "0";
        const iso = this.sessionData.startTime;
        if (iso) {
            dateToUse = iso;
        }

        return new Date(dateToUse);
    }

    get canJoin() {
        return Boolean(
            this.data.networkingTableSize > this.currentAttendees.length
        );
    }

    /**
     * Lifecycle
     */
    created() {
        const promises = [this.fetchAttendees()];

        Promise.allSettled(promises).then(() => {
            // check immediately
            this.handleActiveSessionCheck();

            // check on interval
            this.handleSessionInterval();

            this.isLoading = false;
        });
    }

    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
    }

    /**
     * Methods
     */
    fetchAttendees() {
        const id = this.data.sessionId;
        return new Promise((resolve, reject) => {
            sessionStore
                .getSpeakeasyAttendee(id)
                .then((data) => {
                    const x = data as SessionDataObject;
                    this.sessionData = x;
                    return resolve("ok");
                })
                .catch(() => {
                    return reject();
                });
        });
    }

    enterMeeting(meetingId: string) {
        if (this.isSessionActive) {
            this.$router.push({
                path: `/meeting/SESSION${meetingId}`
            });
        }
    }

    myImage(img: string) {
        return img ? img : require("../../assets/avatar2.png");
    }

    getFullName(attendee: AttendeeBase) {
        const { firstName, lastName } = attendee;
        const name = [firstName, lastName];

        return name.join(" ").trim();
    }

    handleActiveSessionCheck() {
        const session = this.sessionData as SessionDataObject;

        this.isSessionActive = sessionHelpSessionIsActive(session);
        this.isSessionOver = sessionHelpSessionIsOver(session);
    }

    handleSessionInterval() {
        window.clearInterval(this.sessionLabelInterval);
        this.sessionLabelInterval = window.setInterval(() => {
            this.handleActiveSessionCheck();
        }, 1000);
    }
}
